@import 'reset';
@import 'fonts';
:root {
    --fs-header: 75px;
    --fs-medium: 28px;
}
body {
    font-family: 'ZonaPro';
    color: white;
    line-height: 1.2;
    // position: relative;
}
img {
    display: block;
}
.overflow {
    overflow: hidden;
}
.body-inner {
    width: 100vw;
    height: 100vh;
    background-color: #292A4D;
    background-image: url('../img/site-bg.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    // padding-top: 50px;
}
.logo {
    &__wrapper {
        max-width: 220px;
        width: 220px;
        margin: auto;
        position: absolute;
        z-index: 1;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        // margin-top: 50px;
        &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            height: 100%;
            width: 100%;
            top: -10%;
            left: -5%;
            border-radius: 100%;
            background: #CD7BFF;
            filter: blur(40px);
        }
    }

}
.steps {
    max-width: 1216px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform-style: preserve-3d;
    perspective: 1000px;
}
.step {
    position: absolute;
    width: 100%;
    transition: all .6s;
    &.enter-from {
        transform: translateZ(-3000px);
        opacity: 0;
        // transition: 0;
    }
    &.leave-to {
        transform: translateZ(700px);
        opacity: 0;
        &.cards {
            .card:first-child {
                transform: translateX(-100%);
            }
            .card:last-child {
                transform: translateX(100%);
            }
        }
    }
    
}

  
.cards {
    display: flex;
    justify-content: space-between;
    .card {
        flex: 0 0 calc((100% - 30px) / 2);
        width: calc((100% - 30px) / 2);
        transition: all 0.6s;
    }
}

.card {
    background: linear-gradient(180deg, #B73AF2 0%, #8C14C5 100%);
    border-radius: 20px;
    padding: 25px 40px;
    position: relative;
    z-index: 1;
    &__header {
        font-size: var(--fs-header);
        // line-height: 1.2;
        font-weight: 900;
        text-transform: uppercase;
        &+.btn {
            margin-top: 75px;
        }
    }
    &::after {
        content: '';
        position: absolute;
        width: 50%;
        height: 40px;
        top: calc(100%);
        left: 25%;
        transform: perspective(10em) rotateX(40deg) scale(1, 0.5) translateZ(-2.5em);
        border-radius: 100%;
        background: #2B2152;
        filter: blur(10px);
    }
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 80px;
        top: calc(100% - 20px);
        left: 0%;
        transform: perspective(10em) rotateX(40deg) scale(1, 0.5) translateZ(-2.5em);
        border-radius: 100%;
        background: #2B2152;
        filter: blur(25px);
    }
    &--full {
        display: flex;
        align-items: center;
        
    }
    &__badge {
        position: absolute;
        // width: 188px;
        height: 106px;
        top: -40px;
        left: 40px;
        padding: 8px 16px;
        cursor: pointer;
        // left: 384px;
        // top: 243px;

        background: linear-gradient(180deg, #B73AF2 25.94%, #7D0FB0 48.58%, #5A0B80 79.72%);
        border-radius: 13px;
        text-decoration-line: underline;
        text-transform: uppercase;
        font-weight: 600;
    }
    &__left {
        flex: 0 0 37%;
        width: 37%;
        align-self: stretch;
        display: flex;
        flex-direction: column;
    }
    &__rigth {
        flex: 0 0 calc(63% - 15px);
        width: calc(63% - 15px);
        padding-left: 40px;
    }
    &__phone-img {
        max-width: 300px;
        transform: translateY(25px);
    }
    &__title {
        font-size: var(--fs-medium);
        font-weight: 900;
        margin-bottom: 1.4286em;
    }
    &--form {
        display: flex;
        &> *{
            flex: 0 0 50%;
            width: 50%;
        }
    }
    ol {
        counter-reset: custom-cnt;
        margin-bottom: 1.4286em;
    }
    
    li {
        font-size: var(--fs-medium);
        font-weight: 600;
        counter-increment: custom-cnt;
        display: flex;
        &:not(:last-child) {
            margin-bottom: 0.7143em;
        }
        &::before {
            content: counter(custom-cnt) '. ';
            margin-right: 20px;
            font-weight: 900;
        }
    }
    p {
        font-size: var(--fs-medium);
        font-weight: 600;
        &:not(:last-child) {
            margin-bottom: 1.4286em;
        }
    }
}

.messangers {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    max-width: 305px;
    a:not(:last-child) {
        margin-bottom: 15px;
    }
    svg {
        --size: 20px;
        margin-right: 10px;
        flex: 0 0 var(--size);
        width: var(--size);
        height: var(--size);
    }
    
}

button {
    border: none;
    background: transparent;
    cursor: pointer;
}
.btn {
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 53px 16px;
    // gap: 10px;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 700;
    color: white;
    text-decoration: none;
    &--gradient {
        
        background: radial-gradient(72.53% 113.81% at 88.58% 24.1%, #7388FF 0%, #CA73FF 50.34%, #FF739D 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        background-size: cover;
        // outline: 1px solid rgba(255, 255, 255, 0.17);
        // outline-offset: -1px;
        box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2),
                    0px 4px 30px rgba(0, 0, 0, 0.1);
    }
    &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: unset!important;
        height: unset!important;
        padding: 0!important;
        z-index: 1;
        display: block;
    }
    &--whatsapp {
        background: #60B959;
        // border: 1px solid rgba(255, 255, 255, 0.17);
        box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2),
                    0px 4px 30px rgba(0, 0, 0, 0.1);
        // border-radius: 20px;
    }
    &--telegram {
        background: #53B5F0;
        box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2),
                    0px 4px 30px rgba(0, 0, 0, 0.1);
    }
    &--viber {
        background: #775CBC;
        box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2),
                    0px 4px 30px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
    }
    &--file {
        font-size: 16px!important;
        padding: 11px 53px 15px!important;
    }
}

.form-wrapper {
    max-width: 440px;
}
form {
    display: flex;
    flex-direction: column;
   
    .btn {
        margin-right: auto;
        font-family: 'ZonaPro';
    }
    padding-top: 30px;
}
.input-wrapper {
    display: flex;
    position: relative;
    &+& {
        margin-top: 30px;
    }
}
input {
    font-family: 'ZonaPro';
    padding: 10px 0px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    font-size: 16px;
    color: white;
    width: 100%;
    
}
input::placeholder {
    color: white;
    opacity: 1;
}
input:focus {
    border: none;
    border-bottom: 1px solid white;
    outline: none;
}
button[type=submit] {
    margin-top: 40px;
    margin-bottom: 10px;
}
label {
    cursor: pointer;
    margin-right: auto;
    display: inline-block;
}
input[type=file] {
    display: none;
    // height: 0px;
    // overflow: hidden;
    // max-height: 0px;
    // padding: 0px;
    // border: none;
    // opacity: 0;
}
@keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s,
                color 5000s ease-in-out 0s;
}
.file{
    &-wrapper {
        margin: 40px 0px;
        display: flex;
        flex-direction: column;
    }
    &-text {
        font-weight: 600;
        margin-bottom: 25px;
    }
    &-required {
        margin-bottom: 5px;
    }
    &-name:empty {
        display: none;
    }
    &-error:empty {
        display: none;
    }
    
}
.error {
    line-height: 1;
    font-size: 14px;
    color: #333;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    background: white;
    padding: 4px 8px;
    border-radius: 8px;
}
.error:empty {
    display: none;
}
.offerta {
    font-size: 15px;
    text-align: center;
}
.form-response:empty {
    display: none;
}
*.hide-desk {
    display: none;
}

.message {
    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: none;
        align-items: center;
        justify-content: center;
        &.show {
            display: flex;
        }
    }
    &__container {
        position: relative;
        max-width: 300px;
        background: linear-gradient(180deg, #B73AF2 0%, #8C14C5 100%);
        border-radius: 20px;
        padding: 40px;
    }
}


@media (max-width: 1600px) {
    :root {
        --fs-header: 60px;
        --fs-medium: 24px;
    }
    .steps {
        max-width: 992px;
    }
}
@media (max-width: 1200px) {
    :root {
        --fs-header: 48px;
        --fs-medium: 20px;
    }
    .steps {
        max-width: 768px;
    }
}
@media (max-width: 992px) {
    :root {
        --fs-header: 40px;
        --fs-medium: 18px;
    }
    .steps {
        max-width: 768px;
    }
}
@media (max-width: 768px) {
    :root {
        --fs-header: 40px;
        --fs-medium: 16px;
    }
    .steps {
        --pd: 20px;
        max-width: 100%;
        padding-left: var(--pd);
        padding-right: var(--pd);
    }
    .step {
        width: calc(100% - 2*var(--pd));
        left: var(--pd);
    }
}
@media (max-width: 660px) {
    .card__phone-img {
        transform: translateY(20px);
        padding: 0 20px;
        margin-top: 30px;
    }
    .body-inner {
        height: auto;
        min-height: 100vh;
        overflow: hidden;
    }
    .logo {
        &__wrapper {
            position: static;
            transform: none;
            margin: 45px auto;
            width: 205px;
            max-width: 205px;
        }
    }
    .btn {
        font-size: 16px;
        padding: 3px 21px 8px;
        &--file {
            padding: 5px 21px 5px!important;
        }
    }
    button[type="submit"] {
        font-size: 20px;
        padding: 4px 21px 5px;
    }
    .steps {
        justify-content: flex-start;
    }
    .cards {
        flex-direction: column;
        .card {
            flex: 0 0 100%;
            width: 100%;
            &:first-child {
                margin-bottom: 50px;
            }
        }
    }
    .card {
        padding: 20px;
        &__header {
            margin-bottom: 10px;
        }
        &--full, &--form {
            flex-direction: column;
        }
        &--form {
            &>* {
                flex: 0 0 100%;
                width: 100%;
            }
        }
        &__phone-img {
            display: none;
        }
        &__left, &__rigth {
            flex: 0 0 100%;
            width: 100%;
        }
        &__rigth {
            padding-left: 0px;
        }
        &__badge {
            font-size: 15px;
            left: 18px;
            top: -36px;
        }
    }
    .messangers {
        display: none;
    }
    *.hide-desk {
        display: block;
    }
    .file {
        &-required {
            font-size: 15px;
            margin-bottom: 20px;
        }
    }
    .offerta {
        margin-top: 20px;
    }
    .messangers {
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        .btn {
            padding: 7px 21px 11px !important;
            font-size: 20px;
            width: 100%;
        }
    }
}