/**
* CSS Generated by Andryushkin.ru
* All fonts for the site https://andryushkin.ru/fonts/
**/


/* Ultra */

@font-face {
    font-family: 'ZonaPro';
    src: local('ZonaPro'),
         url('../fonts/ZonaPro-Ultra.ttf') format('truetype'),
         url('../fonts/ZonaPro-Ultra.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ZonaPro-Ultra.woff') format('woff'); 
    font-weight: normal;
    font-style: normal;
}

/* Regular */

@font-face {
    font-family: 'ZonaPro';
    src: local('ZonaPro'),
         url('../fonts/ZonaPro-Regular.ttf') format('truetype'),
         url('../fonts/ZonaPro-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ZonaPro-Regular.woff') format('woff'); 
    font-weight: 400;
    font-style: normal;
}

/* Black */

@font-face {
    font-family: 'ZonaPro';
    src: local('ZonaPro'),
         url('../fonts/ZonaPro-Black.ttf') format('truetype'),
         url('../fonts/ZonaPro-Black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ZonaPro-Black.woff') format('woff'); 
    font-weight: 900;
    font-style: normal;
}

/* Bold */

@font-face {
    font-family: 'ZonaPro';
    src: local('ZonaPro'),
         url('../fonts/ZonaPro-Bold.ttf') format('truetype'),
         url('../fonts/ZonaPro-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ZonaPro-Bold.woff') format('woff'); 
    font-weight: 700;
    font-style: normal;
}

/* SemiBold */

@font-face {
    font-family: 'ZonaPro';
    src: local('ZonaPro'),
         url('../fonts/ZonaPro-SemiBold.ttf') format('truetype'),
         url('../fonts/ZonaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ZonaPro-SemiBold.woff') format('woff'); 
    font-weight: 600;
    font-style: normal;
}
