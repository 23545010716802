html {
  box-sizing: border-box;
  font-size: 16px;
}

*, :before, :after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

@font-face {
  font-family: ZonaPro;
  src: local(ZonaPro), url("../fonts/ZonaPro-Ultra.72011a38.ttf") format("truetype"), url("../fonts/ZonaPro-Ultra.fe99b974.eot#iefix") format("embedded-opentype"), url("../fonts/ZonaPro-Ultra.60df1a36.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ZonaPro;
  src: local(ZonaPro), url("../fonts/ZonaPro-Regular.24a39300.ttf") format("truetype"), url("../fonts/ZonaPro-Regular.2f89a7d7.eot#iefix") format("embedded-opentype"), url("../fonts/ZonaPro-Regular.8b929356.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: ZonaPro;
  src: local(ZonaPro), url("../fonts/ZonaPro-Black.361fa795.ttf") format("truetype"), url("../fonts/ZonaPro-Black.5e42af41.eot#iefix") format("embedded-opentype"), url("../fonts/ZonaPro-Black.af5e794b.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: ZonaPro;
  src: local(ZonaPro), url("../fonts/ZonaPro-Bold.726d12f9.ttf") format("truetype"), url("../fonts/ZonaPro-Bold.4c66c752.eot#iefix") format("embedded-opentype"), url("../fonts/ZonaPro-Bold.9ed5ec3d.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: ZonaPro;
  src: local(ZonaPro), url("../fonts/ZonaPro-SemiBold.76bc2f03.ttf") format("truetype"), url("../fonts/ZonaPro-SemiBold.d1867074.eot#iefix") format("embedded-opentype"), url("../fonts/ZonaPro-SemiBold.a48fd76d.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

:root {
  --fs-header: 75px;
  --fs-medium: 28px;
}

body {
  color: #fff;
  font-family: ZonaPro;
  line-height: 1.2;
}

img {
  display: block;
}

.overflow {
  overflow: hidden;
}

.body-inner {
  background-color: #292a4d;
  background-image: url("../img/site-bg.43d205f0.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.logo__wrapper {
  z-index: 1;
  width: 220px;
  max-width: 220px;
  margin: auto;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.logo__wrapper:before {
  content: "";
  z-index: -1;
  filter: blur(40px);
  background: #cd7bff;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: -10%;
  left: -5%;
}

.steps {
  transform-style: preserve-3d;
  perspective: 1000px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1216px;
  height: 100%;
  margin: auto;
  display: flex;
}

.step {
  width: 100%;
  transition: all .6s;
  position: absolute;
}

.step.enter-from {
  opacity: 0;
  transform: translateZ(-3000px);
}

.step.leave-to {
  opacity: 0;
  transform: translateZ(700px);
}

.step.leave-to.cards .card:first-child {
  transform: translateX(-100%);
}

.step.leave-to.cards .card:last-child {
  transform: translateX(100%);
}

.cards {
  justify-content: space-between;
  display: flex;
}

.cards .card {
  flex: 0 0 calc(50% - 15px);
  width: calc(50% - 15px);
  transition: all .6s;
}

.card {
  z-index: 1;
  background: linear-gradient(#b73af2 0%, #8c14c5 100%);
  border-radius: 20px;
  padding: 25px 40px;
  position: relative;
}

.card__header {
  font-size: var(--fs-header);
  text-transform: uppercase;
  font-weight: 900;
}

.card__header + .btn {
  margin-top: 75px;
}

.card:after {
  content: "";
  filter: blur(10px);
  background: #2b2152;
  border-radius: 100%;
  width: 50%;
  height: 40px;
  position: absolute;
  top: 100%;
  left: 25%;
  transform: perspective(10em)rotateX(40deg)scale(1, .5)translateZ(-2.5em);
}

.card:before {
  content: "";
  filter: blur(25px);
  background: #2b2152;
  border-radius: 100%;
  width: 100%;
  height: 80px;
  position: absolute;
  top: calc(100% - 20px);
  left: 0%;
  transform: perspective(10em)rotateX(40deg)scale(1, .5)translateZ(-2.5em);
}

.card--full {
  align-items: center;
  display: flex;
}

.card__badge {
  cursor: pointer;
  text-transform: uppercase;
  background: linear-gradient(#b73af2 25.94%, #7d0fb0 48.58%, #5a0b80 79.72%);
  border-radius: 13px;
  height: 106px;
  padding: 8px 16px;
  font-weight: 600;
  text-decoration-line: underline;
  position: absolute;
  top: -40px;
  left: 40px;
}

.card__left {
  flex-direction: column;
  flex: 0 0 37%;
  align-self: stretch;
  width: 37%;
  display: flex;
}

.card__rigth {
  flex: 0 0 calc(63% - 15px);
  width: calc(63% - 15px);
  padding-left: 40px;
}

.card__phone-img {
  max-width: 300px;
  transform: translateY(25px);
}

.card__title {
  font-size: var(--fs-medium);
  margin-bottom: 1.4286em;
  font-weight: 900;
}

.card--form {
  display: flex;
}

.card--form > * {
  flex: 0 0 50%;
  width: 50%;
}

.card ol {
  counter-reset: custom-cnt;
  margin-bottom: 1.4286em;
}

.card li {
  font-size: var(--fs-medium);
  counter-increment: custom-cnt;
  font-weight: 600;
  display: flex;
}

.card li:not(:last-child) {
  margin-bottom: .7143em;
}

.card li:before {
  content: counter(custom-cnt) ". ";
  margin-right: 20px;
  font-weight: 900;
}

.card p {
  font-size: var(--fs-medium);
  font-weight: 600;
}

.card p:not(:last-child) {
  margin-bottom: 1.4286em;
}

.messangers {
  flex-direction: column;
  max-width: 305px;
  margin-top: auto;
  display: flex;
}

.messangers a:not(:last-child) {
  margin-bottom: 15px;
}

.messangers svg {
  --size: 20px;
  flex: 0 0 var(--size);
  width: var(--size);
  height: var(--size);
  margin-right: 10px;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

.btn {
  color: #fff;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 53px 16px;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.btn--gradient {
  background: radial-gradient(72.53% 113.81% at 88.58% 24.1%, #7388ff 0%, #ca73ff 50.34%, #ff739d 100%) 0 0 / cover;
  box-shadow: inset 0 0 0 1px #fff3, 0 4px 30px #0000001a;
}

.btn-close {
  z-index: 1;
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
}

.btn--whatsapp {
  background: #60b959;
  box-shadow: inset 0 0 0 1px #fff3, 0 4px 30px #0000001a;
}

.btn--telegram {
  background: #53b5f0;
  box-shadow: inset 0 0 0 1px #fff3, 0 4px 30px #0000001a;
}

.btn--viber {
  background: #775cbc;
  border-radius: 20px;
  box-shadow: inset 0 0 0 1px #fff3, 0 4px 30px #0000001a;
}

.btn--file {
  padding: 11px 53px 15px !important;
  font-size: 16px !important;
}

.form-wrapper {
  max-width: 440px;
}

form {
  flex-direction: column;
  padding-top: 30px;
  display: flex;
}

form .btn {
  margin-right: auto;
  font-family: ZonaPro;
}

.input-wrapper {
  display: flex;
  position: relative;
}

.input-wrapper + .input-wrapper {
  margin-top: 30px;
}

input {
  color: #fff;
  background-color: #0000;
  border: none;
  border-bottom: 1px solid #fff;
  width: 100%;
  padding: 10px 0;
  font-family: ZonaPro;
  font-size: 16px;
}

input::placeholder {
  color: #fff;
  opacity: 1;
}

input:focus {
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
}

button[type="submit"] {
  margin-top: 40px;
  margin-bottom: 10px;
}

label {
  cursor: pointer;
  margin-right: auto;
  display: inline-block;
}

input[type="file"] {
  display: none;
}

@keyframes autofill {
  to {
    color: #666;
    background: none;
  }
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out, color 5000s ease-in-out;
}

input:-webkit-autofill:hover {
  transition: background-color 5000s ease-in-out, color 5000s ease-in-out;
}

input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out, color 5000s ease-in-out;
}

input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out, color 5000s ease-in-out;
}

.file-wrapper {
  flex-direction: column;
  margin: 40px 0;
  display: flex;
}

.file-text {
  margin-bottom: 25px;
  font-weight: 600;
}

.file-required {
  margin-bottom: 5px;
}

.file-name:empty, .file-error:empty {
  display: none;
}

.error {
  color: #333;
  background: #fff;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
}

.error:empty {
  display: none;
}

.offerta {
  text-align: center;
  font-size: 15px;
}

.form-response:empty, .hide-desk {
  display: none;
}

.message__backdrop {
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.message__backdrop.show {
  display: flex;
}

.message__container {
  background: linear-gradient(#b73af2 0%, #8c14c5 100%);
  border-radius: 20px;
  max-width: 300px;
  padding: 40px;
  position: relative;
}

@media (max-width: 1600px) {
  :root {
    --fs-header: 60px;
    --fs-medium: 24px;
  }

  .steps {
    max-width: 992px;
  }
}

@media (max-width: 1200px) {
  :root {
    --fs-header: 48px;
    --fs-medium: 20px;
  }

  .steps {
    max-width: 768px;
  }
}

@media (max-width: 992px) {
  :root {
    --fs-header: 40px;
    --fs-medium: 18px;
  }

  .steps {
    max-width: 768px;
  }
}

@media (max-width: 768px) {
  :root {
    --fs-header: 40px;
    --fs-medium: 16px;
  }

  .steps {
    --pd: 20px;
    padding-left: var(--pd);
    padding-right: var(--pd);
    max-width: 100%;
  }

  .step {
    width: calc(100% - 2 * var(--pd));
    left: var(--pd);
  }
}

@media (max-width: 660px) {
  .card__phone-img {
    margin-top: 30px;
    padding: 0 20px;
    transform: translateY(20px);
  }

  .body-inner {
    height: auto;
    min-height: 100vh;
    overflow: hidden;
  }

  .logo__wrapper {
    width: 205px;
    max-width: 205px;
    margin: 45px auto;
    position: static;
    transform: none;
  }

  .btn {
    padding: 3px 21px 8px;
    font-size: 16px;
  }

  .btn--file {
    padding: 5px 21px !important;
  }

  button[type="submit"] {
    padding: 4px 21px 5px;
    font-size: 20px;
  }

  .steps {
    justify-content: flex-start;
  }

  .cards {
    flex-direction: column;
  }

  .cards .card {
    flex: 0 0 100%;
    width: 100%;
  }

  .cards .card:first-child {
    margin-bottom: 50px;
  }

  .card {
    padding: 20px;
  }

  .card__header {
    margin-bottom: 10px;
  }

  .card--full, .card--form {
    flex-direction: column;
  }

  .card--form > * {
    flex: 0 0 100%;
    width: 100%;
  }

  .card__phone-img {
    display: none;
  }

  .card__left, .card__rigth {
    flex: 0 0 100%;
    width: 100%;
  }

  .card__rigth {
    padding-left: 0;
  }

  .card__badge {
    font-size: 15px;
    top: -36px;
    left: 18px;
  }

  .messangers {
    display: none;
  }

  .hide-desk {
    display: block;
  }

  .file-required {
    margin-bottom: 20px;
    font-size: 15px;
  }

  .offerta {
    margin-top: 20px;
  }

  .messangers {
    margin: 40px auto 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .messangers .btn {
    width: 100%;
    font-size: 20px;
    padding: 7px 21px 11px !important;
  }
}

/*# sourceMappingURL=styles.81d16d80.css.map */
